import { Content } from '@/lib/model';
import { getContentMetadata } from '@/common/utils';
import { PlatformMediaBox, PlatformTitle } from '@/app/components/cards/common';
import { ContentLink } from '../../link/ContentLink';

type Props = {
  content?: Content;
};

export function EpisodeCard({ content }: Props) {
  const { mobileContentMetadata, desktopContentMetadata } =
    getContentMetadata(content);
  return (
    <div className="w-full flex flex-row sm:flex-col gap-3 no-underline article-hover-effect">
      <div className="max-w-[100px] sm:max-w-none sm:aspect-auto aspect-square w-full h-fit rounded bg-no-repeat relative">
        <PlatformMediaBox
          mobileContentMetadata={mobileContentMetadata}
          desktopContentMetadata={desktopContentMetadata}
          href={`/article/${content?.slug}`}
          className="sm:h-auto rounded sm:w-full w-[100px] h-full"
          alt={content?.title ?? 'image'}
          width={260}
          height={195}
          viewInDetailPage
          hidePlaceholder
        />
      </div>

      <ContentLink
        mobileContentMetadata={mobileContentMetadata}
        desktopContentMetadata={desktopContentMetadata}
        className="font-playfair text-base lg:text-lg font-semibold text-grey-1 leading-custom_4 lg:leading-6 line-clamp-3"
      >
        <PlatformTitle
          mobileContentMetadata={mobileContentMetadata}
          desktopContentMetadata={desktopContentMetadata}
        />
      </ContentLink>
    </div>
  );
}
