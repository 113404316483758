import React from 'react';
import { ArticleLink } from '@/app/components/link/ArticleLink';
import { Content } from '@/lib/model';

type Props = {
  content?: Content;
  showDescription?: boolean;
};

export function ItemCard({ content, showDescription = true }: Props) {
  return (
    <ArticleLink
      articleSlug={content?.slug}
      className="w-full flex flex-col gap-3 content-baseline no-underline article-hover-effect"
    >
      <div className="font-playfair text-grey-11 text-lg leading-normal font-medium md:text-xl md:leading-custom_5">
        {content?.title}
      </div>

      {content?.description && showDescription && (
        <div className="font-notosans text-grey-8 font-normal text-xs2 leading-normal">
          {content.description}
        </div>
      )}
    </ArticleLink>
  );
}
